.rui-box,
.rui-box-1,
.rui-box-2,
.rui-box-3,
.rui-box-4,
.rui-box-5 {
    position: relative;
    display: block;
    z-index: 1;
}
.rui-box-1 {
    padding: 30px 15px;
}
