.rui-messenger {
    position: relative;
    padding-top: 76px;
    background-color: #fff;

    .media {
        &.media-filled > .media-link {
            border-radius: 0;
        }
        .media-body {
            flex: 0 0 calc(100% - 55px);
            max-width: calc(100% - 55px);
        }
        .media-subtitle {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    // Mini
    &.rui-messenger-mini {
        height: 100%;
        padding-top: 75px;
        padding-bottom: 75px;

        &.open {
            > .rui-messenger-head,
            > .rui-messenger-list,
            > .rui-messenger-footer {
                position: absolute;
                height: 0;
                overflow: hidden;
                visibility: hidden;
                opacity: 0;
            }
            > .rui-messenger-chat {
                position: relative;
                height: calc(100% + 150px);
                margin-top: -75px;
                visibility: visible;
                opacity: 1;
            }
        }
        .rui-messenger-head {
            justify-content: center;
            padding: 30px;
        }
        .rui-messenger-list {
            height: 100%;
        }
        .rui-messenger-footer {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: space-between;
            right: 0;
            bottom: 0;
            left: 0;
            min-height: 75px;
            padding: 20px 30px;
            z-index: 1;
        }
        // Search
        .rui-messenger-search {
            position: absolute;
            display: flex;
            align-items: center;
            top: 24px;
            left: 30px;
            width: auto;

            > .btn {
                margin: -6px 0 -10px -12px;

                &:focus,
                &.focus {
                    + input {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
            > input {
                position: absolute;
                top: -6px;
                right: 30px;
                left: 25px;
                width: 312px;
                visibility: hidden;
                background-color: #fff;
                opacity: 0;
                will-change: opacity;
                transition: opacity .15s ease-in-out, visibility .15s ease-in-out;

                &:focus,
                &.focus {
                    visibility: visible;
                    box-shadow: none;
                    opacity: 1;
                }
            }
        }
        .rui-messenger-chat {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            padding: 75px 0;
            visibility: hidden;
            opacity: 0;
            will-change: opacity;
            transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
            z-index: -1;

            .rui-messenger-head {
                position: absolute;
                justify-content: space-between;
                top: 0;
                right: 0;
                left: 0;
            }
            .rui-messenger-body {
                right: 0;
                left: 0;
                height: 100%;
                max-height: initial;
            }
            .rui-messenger-footer {
                position: absolute;
                display: block;
                right: 0;
                bottom: 0;
                left: 0;
                padding: 28px 30px;
            }
        }
    }
    // Full
    &.rui-messenger-full {
        display: flex;
        border: 1px solid $color_separator;
        border-radius: .25rem;

        .rui-messenger-head {
            height: 75px;
            border-bottom: 1px solid $color_separator;

            @include media-breakpoint-down(xs) {
                border: 1px solid $color_separator;
                border-radius: .25rem;
            }
        }
        .rui-messenger-list {
            flex: 0 0 30%;
            max-width: 30%;
            border-right: 1px solid $color_separator;

            @include media-breakpoint-down(xs) {
                padding-right: 8px;
                padding-left: 0;

                .collapse,
                .collapsing {
                    margin-right: 0;
                    margin-left: 0;
                }
                .rui-messenger-collapse-btn > .rui-icon {
                    margin-right: 5px;
                }
                .media {
                    &::before {
                        left: 30px;
                    }
                    > .media-link {
                        padding-right: 0;
                        padding-left: 0;
                    }
                    > .media-icon {
                        right: 0;
                    }
                }
            }
        }
        .rui-messenger-item {
            flex-wrap: nowrap;
        }
        > .rui-messenger-footer {
            position: absolute;
            display: none;
            right: 0;
            bottom: 0;
            left: 0;
            min-height: 75px;
            padding: 20px 30px;
            border-top: 1px solid $color_separator;
            z-index: 1;

            @include media-breakpoint-down(xs) {
                border: 1px solid $color_separator;
                border-radius: .25rem;
            }
        }
        .rui-messenger-head-search {
            flex: 0 0 30%;
            max-width: 30%;
            padding: 18px 30px 18px 17px;
        }
        .rui-messenger-head-buttons {
            flex: 0 0 70%;
            max-width: 70%;
            padding: 21px 30px;
            margin: 0;

            > ul {
                display: flex;
                align-items: center;
                padding: 0;
                margin: 0;
                margin: -30px -10px 0;

                > li {
                    display: inline-flex;
                    padding: 30px 10px 0;
                    list-style: none;
                }
            }
        }
        .rui-messenger-chat {
            flex: 0 0 70%;
            max-width: 70%;

            .rui-messenger-head .rui-messenger-head-buttons {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .rui-messenger-body {
                height: 400px;
                padding: 30px 30px 43px;

                @include media-breakpoint-down(xs) {
                    padding-right: 8px;
                    padding-left: 0;
                }
            }
            .rui-messenger-footer {
                border-top: 1px solid $color_separator;

                @include media-breakpoint-down(xs) {
                    border: 1px solid $color_separator;
                    border-radius: .25rem;
                }
            }
        }
        @include media-breakpoint-down(lg) {
            .rui-messenger-list,
            .rui-messenger-head-search {
                flex: 0 0 40%;
                max-width: 40%;
            }
            .rui-messenger-chat,
            .rui-messenger-head-buttons {
                flex: 0 0 60%;
                max-width: 60%;
            }
        }
        @include media-breakpoint-down(md) {
            display: block;
            padding-top: 75px;
            padding-bottom: 75px;
            border: 1px solid $color_separator;
            border-radius: .25rem;

            &.open {
                > .rui-messenger-head,
                > .rui-messenger-list,
                > .rui-messenger-footer {
                    position: absolute;
                    height: 0;
                    overflow: hidden;
                    visibility: hidden;
                    opacity: 0;
                }
                > .rui-messenger-chat {
                    position: relative;
                    margin-top: -75px;
                    margin-bottom: -75px;
                    visibility: visible;
                    opacity: 1;
                }
            }
            .rui-messenger-head {
                position: absolute;
            }
            .rui-messenger-head-search {
                flex: 0 0 100%;
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
                padding-right: 20px;
            }
            .rui-messenger-list {
                flex: none;
                max-width: none;
                height: 380px;
                border-right: 0;

            }
            .media.media-filled.active > .rui-messenger-item {
                background-color: transparent;
            }
            > .rui-messenger-footer {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .rui-messenger-chat {
                position: absolute;
                flex: none;
                top: 0;
                right: 0;
                left: 0;
                max-width: none;
                padding: 75px 0;
                visibility: hidden;
                opacity: 0;
                will-change: opacity;
                transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
                z-index: -1;

                .rui-messenger-head {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                }
                .rui-messenger-footer {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    padding: 28px 30px;
                }
            }
        }
        @include media-breakpoint-down(xs) {
            border: 0;
        }
    }

    // List
    .rui-messenger-list {
        padding: 30px 30px 43px;

        // Collapse
        .collapse,
        .collapsing {
            margin-right: -30px;
            margin-left: -30px;
        }
    }

    // Head
    .rui-messenger-head {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: 0;
        right: 0;
        left: 0;
        z-index: 2;
    }
    .rui-messenger-head-empty {
        width: 25px;
    }

    // Chat
    .rui-messenger-chat {
        position: relative;
        background-color: #fff;

        .rui-messenger-body.rui-scrollbar {
            opacity: 0;
            transition: opacity .15s ease-in-out;

            &.os-host {
                opacity: 1;
            }
        }

        &.open {
            visibility: visible;
            opacity: 1;
        }
        &.show {
            z-index: 2;
        }

        .rui-messenger-custom-file {
            position: absolute;
            visibility: hidden;
        }
        .rui-messenger-head {
            position: relative;
            padding: 30px;
        }
        .rui-messenger-body {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 30px;
            border: 0;

            &::before {
                content: "";
                display: block;
                margin-bottom: auto;
            }

            .os-viewport {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                height: 100%;

                &::before {
                    content: "";
                    display: block;
                    margin-bottom: auto;
                }
            }

            > div {
                display: block;
            }
        }
        .rui-messenger-footer {
            padding: 30px;

            > .input-group {
                width: auto;
                margin: -11px;
            }
        }

        // Datetime
        .rui-messenger-message-datetime {
            display: block;
            padding: 26px 0;
            color: $color_grey_4;
            text-align: center;

            &:nth-child(2) {
                padding-top: 0;
                margin-top: -4px;
            }
        }

        // Message
        .rui-messenger-message {
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
            > ul {
                display: inline-block;
                order: 1;
                padding: 0;
                margin: 0;
                color: #fff;
                text-align: right;
                background-color: rgba($color_brand, .8);
                border-radius: 6px 6px 0;

                > li {
                    position: relative;
                    padding: 10px 15px;
                    list-style: none;

                    + li {
                        padding-top: 0;
                    }
                }
            }

            // Time
            .rui-messenger-message-time {
                position: absolute;
                top: 100%;
                right: 0;
                margin-top: 5px;
                color: $color_grey_4;
            }

            // Edit btns
            &:not(.rui-messenger-message-incoming) {
                > ul {
                    order: 1;
                }
                > .rui-messenger-message-edit {
                    order: 0;
                    margin-right: 7px;
                    margin-bottom: 3px;
                }
            }
            .rui-messenger-message-edit {
                display: flex;
                opacity: 0;
                will-change: opacity;
                transition: opacity .15s ease-in-out;

                > .btn {
                    padding: 10px 8px;
                    color: $color_grey_4;
                    border: 0;

                    &:hover,
                    &.hover {
                        color: $color_text_1;
                    }
                    &:focus,
                    &.focus {
                        box-shadow: none;
                    }
                }
            }
            // Structure (Block)
            &.rui-messenger-message-structure {
                display: none;

                + .rui-messenger-datetime {
                    padding-top: 0;
                    margin-top: -4px;
                }
            }
            // Incoming
            &.rui-messenger-message-incoming {
                justify-content: flex-start;

                .rui-messenger-message-time {
                    right: auto;
                    left: 0;
                }

                > ul {
                    color: $color_text_2;
                    text-align: left;
                    background-color: $color_grey_1;
                    border-radius: 6px 6px 6px 0;
                }
            }
            &:hover,
            &.hover {
                .rui-messenger-message-edit {
                    opacity: 1;
                }
            }
        }
        // Textarea
        .rui-messenger-textarea {
            height: 38px;
            min-height: 38px;
            max-height: 38px;
            padding-top: 8px;
            padding-bottom: 8px;
            resize: none;
        }
    }

    // Back btn
    .rui-messenger-back {
        margin: -12px 0 -11px -16px;
    }
    // Collapse
    .rui-messenger-collapse {
        padding-bottom: 15px;
    }
    .rui-messenger-collapse-btn {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin-top: -5px;
        margin-bottom: 10px;
        font-size: 12px;
        color: $color_grey_4;
        text-transform: initial;
        transition: color .15s ease-in-out;

        &.collapsed > .rui-icon {
            transform: rotate(0);
        }

        > .rui-icon {
            margin-right: -4px;
            transition: transform .15s ease-in-out;
            transform: rotate(90deg);
        }

        &:hover,
        &.hover {
            color: $color_text_1;
        }

        &:focus,
        &.focus {
            box-shadow: none;
        }
    }
}
