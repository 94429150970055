.breadcrumb {
    position: relative;
    padding: 0;
    margin: -10px -6px 0;
    font-size: .92rem;
    background: none;

    .breadcrumb-item,
    .breadcrumb-item.show {
        position: relative;
        display: inline-block;
        padding: 5px 6px 0;
        color: $color_grey_5;

        .rui-icon {
            margin-top: -2px;
        }

        > a {
            position: relative;
            display: block;
            padding: 0;
            font-size: .92rem;
            color: $color_grey_5;
            transition: color .15s ease-in-out, background-color .15s ease-in-out;

            &::after {
                content: "";
                position: absolute;
                top: -3px;
                right: -6px;
                bottom: -3px;
                left: -6px;
                background-color: $color_grey_1;
                border-radius: 3px;
                opacity: 0;
                transition: opacity .15s ease-in-out;
                z-index: -1;
            }
        }
        &:hover,
        &.hover {
            > a {
                color: $color_secondary;
                text-decoration: none;

                &::after {
                    background-color: $color_grey_1;
                    opacity: 1;
                }
            }
        }
    }
}
