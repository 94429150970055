.list-group-item {
    padding: .75rem 1.45rem;
    border-color: $color_separator;

    &.active {
        background-color: $color_brand;
        border-color: $color_brand;
    }
}
.list-group-item-action {
    transition: background-color .1s ease-in-out, color .1s ease-in-out;

    &:hover,
    &.hover,
    &:focus,
    &.focus {
        color: $color_text_1;
        background-color: $color_grey_1;
    }
    &:active {
        color: $color_text_1;
        background-color: $color_grey_2;
    }

    &.active {
        background-color: $color_brand;

        &:hover,
        &.hover,
        &:focus,
        &.focus,
        &:active {
            color: #fff;
        }
    }
}
.list-group-item-brand,
.list-group-item-primary,
.list-group-item-secondary,
.list-group-item-success,
.list-group-item-danger,
.list-group-item-warning,
.list-group-item-info,
.list-group-item-light,
.list-group-item-dark {
    color: $color_secondary;
    background-color: #fff;
    border-left: 0;

    &:first-child {
        border-top-left-radius: 0;
    }
    &:last-child {
        border-bottom-left-radius: 0;
    }
    &.list-group-item-action {
        &:hover,
        &.hover,
        &:focus,
        &.focus {
            color: $color_text_1;
            background-color: $color_grey_1;
        }
        &:active {
            color: $color_text_1;
            background-color: $color_grey_2;
        }
    }
}
@each $name, $color in $colors_alerts {
    .list-group-item-#{$name} {
        box-shadow: inset 2px 0 0 $color;
    }
}
