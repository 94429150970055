.rui-search {
    // Head
    .rui-search-head {
        position: relative;
        padding-bottom: 30px;
        margin-top: -12px;
        margin-bottom: -15px;
        z-index: 1;
    }
    // Body
    .rui-search-body {
        padding-top: 30px;
    }
}
