.media {
    position: relative;
    flex-wrap: wrap;

    &.media-show > .media-icon {
        visibility: visible;
        opacity: 1;
    }

    + .media {
        margin-top: 30px;
    }
    .media {
        margin-top: 30px;
    }

    &.media-retiring .media-title {
        justify-content: flex-start;
    }

    &.media-filled {
        + .media-filled {
            margin-top: 0;
        }
        > .media-icon {
            top: 10px;
            right: 20px;
        }

        &::before {
            top: 49px;
            left: 60px;
        }

        > .media-link {
            display: flex;
            width: 100%;
            padding: 15px 30px;
            border-radius: .25rem;
            transition: background-color .15s ease-in-out;
        }

        &:hover,
        &.hover,
        &.active {
            > .media-link {
                background-color: $color_grey_1;

                > .media-img {
                    color: $color_grey_5;
                    background-color: $color_grey_2;

                    > img {
                        opacity: .8;
                    }
                }
            }
        }
        &:hover > .media-icon,
        &.hover > .media-icon {
            visibility: visible;
            opacity: 1;
        }
    }

    > .media-link {
        display: flex;
        align-items: flex-start;
        width: 100%;

        + .media-content {
            margin-top: 20px;
            overflow: hidden;
        }
    }
    > .media-icon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -5px;
        right: -5px;
        width: 30px;
        height: 30px;
        color: $color_grey_4;
        visibility: hidden;
        opacity: 0;
        transition: visibility .15s ease-in-out, opacity .15s ease-in-out, color .15s ease-in-out;

        &:hover,
        &.hover {
            color: $color_text_1;
        }
    }
    .media-content {
        width: 100%;
    }

    @each $name, $color in $colors_alerts {
        &.media-#{$name} .media-img::before {
            background-color: $color;
            box-shadow: 0 0 0 2px #fff;
        }
    }

    .media-img {
        position: relative;
        display: inline-flex;
        flex: 0 0 45px;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1.145rem;
        line-height: 0;
        color: $color_grey_4;
        background-color: $color_grey_1;
        border-radius: 100%;
        transition: opacity .15s ease-in-out, color .15s ease-in-out, background-color .15s ease-in-out;

        &::before {
            content: "";
            position: absolute;
            top: 40px;
            left: 34px;
            width: 5px;
            height: 5px;
            border-radius: 100%;
            z-index: 1;
        }

        + .media-body {
            margin-left: 15px;
        }

        > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // stylelint-disable-next-line
            font-family: "object-fit: cover";
            object-fit: cover;
            border-radius: 100%;
            opacity: 1;
            will-change: opacity;
            transition: opacity .15s ease-in-out;
        }
        > .icon-file {
            width: auto;
            border-radius: 0;
        }

        + .rui-search-item-content {
            padding-top: 4px;
            margin-left: 15px;
        }
    }
    .media-title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: -4px;
        font-size: 1.08rem;
        color: $color_text_1;

        + .media-subtitle {
            margin-top: 5px;
        }
        .media-time {
            margin-bottom: -1px;
            margin-left: 15px;
        }
    }
    .media-time {
        font-size: 12px;
        color: $color_grey_4;
    }
    .media-subtitle {
        display: block;
        margin-bottom: -4px;
        color: $color_grey_6;
    }

    &:hover,
    &.hover,
    &:hover > .media-link,
    &.hover > .media-link,
    > .media-link:hover,
    > .media-link.hover {
        > .media-icon {
            visibility: visible;
            opacity: 1;
        }
        > .media-img {
            color: $color_grey_5;
            background-color: $color_grey_2;

            > img {
                opacity: .8;
            }
        }
    }

    &:hover,
    &.hover,
    &:focus,
    &.focus,
    > .media-link:hover,
    > .media-link.hover,
    > .media-link:focus,
    > .media-link.focus {
        text-decoration: none;
    }
}

// Country
.media-country {
    .media-img {
        flex: 0 0 20px;
        width: 20px;
        height: 20px;
    }
    .media-body {
        display: flex;
        justify-content: space-between;
    }
    .media-country-price {
        color: $color_grey_6;
    }
}
