.rui-gallery {
    .rui-gallery-item {
        position: relative;
        display: block;
        height: 100%;
        overflow: hidden;
        border-radius: .25rem;

        &:focus {
            outline: none;
        }

        .rui-gallery-item-overlay {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            font-size: 5rem;
            line-height: 0;
            color: $color_text_2;
            background-color: rgba($color_grey_1, .85);
            opacity: 0;
            will-change: opacity;
            transition: opacity .15s ease-in-out;
            z-index: 1;

            > .rui-icon {
                width: 26px;
                height: 26px;
                opacity: 0;
                will-change: opacity;
                transition: opacity .15s ease-in-out;
                transition-delay: .1s;
            }
        }

        > img {
            width: 100%;
            height: 100%;
            // stylelint-disable-next-line
            font-family: "object-fit: cover";
            object-fit: cover;
            will-change: transform;
            transition: transform .4s ease-in-out;
            transform: scale(1);
        }

        &:hover {
            .rui-gallery-item-overlay {
                opacity: 1;

                > .rui-icon {
                    opacity: 1;
                }
            }
        }
    }
}
