/* 
body{
    background-color: #f9fafb !important;
}

.bg-secondary{
    background-color: #f9fafb !important;
}
.text-primary{
    color: #4C7B5F !important;
}
.text-blue{
    color: #004EEB !important;
}
.text-green{
    color: #12B76A !important;
}
.btn-primary{
    background-color: #4C7B5F !important;
    color: #fff !important;
    border: none !important;
/* } */

/* .form-check-input:checked{
    border-color: #4C7B5F;
    background-color: #4C7B5F;
} */

.body {
  background-color: #B3D0BF;
}

.container{
  background-color: white;
  border-style: none;
 box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.06);
}
 
.delete {
  padding-left: 20px; 
  padding-right: 20px; 
}

.deletion-data li {
    margin-bottom: 1rem !important;
} 

.otp-field{
    width: 60px !important;
    height: 60px !important;
    border: 1px solid #4C7B5F !important;
    font-size: 32px !important;
    font-weight: bold !important;
    color: #4C7B5F !important;
    -webkit-appearance: none;
    appearance: none;
}

@media only screen and (max-width: 600px) {
  .otp-field{
    width: 42px !important;
    height: 40px !important;
    font-size: 14px !important; 
} 
  .font-resend{
    font-size: 13px !important;
  }
}
.shadow{
    box-shadow: -1px 5px 16px -7px rgba(0,0,0,0.75);
    -webkit-box-shadow: -1px 5px 16px -7px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 5px 16px -7px rgba(0,0,0,0.75);
}


.input-group-merge .form-control:not(:first-child) {
  padding-left: 0;
  border-left: 0;
}

.input-group-merge .form-control:not(:last-child) {
  padding-right: 0;
  border-right: 0;
}



input:focus {
  outline: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */

